<template>
  <c-box p="5" w="100%" bg="#e5f3fd">
    <div>
      <c-flex>
        <c-button rounded="xl" size="xs" left-icon="suitcase" variant-color="vc-orange" variant="solid">
          Businesses
        </c-button>
        <c-button rounded="xl" size="xs" right-icon="users" variant-color="vc-orange" variant="outline">
          People
        </c-button>
      </c-flex>
      <c-flex v-if="loading" justify="center" align="center">
        <c-spinner size="xl" color="orange.500" thickness="2px"></c-spinner>
      </c-flex>
      <div v-else>
        <div
            :key="item.id"
            v-for="item in companiesResults"
        >
          <business-card :business="item" />
        </div>

        <div
            :key="item.id"
            v-for="item in usersResults"
        >
          <people-card :person="item" />
        </div>
      </div>
      <c-flex style="margin-right: -280px; margin-top: 50px" justify="start">
        <c-button :disabled="resultsOffset === 0" @click="moveBack" ariaLabel="next button" left-icon="chevron-left">Back</c-button>
        <c-button :disabled="resultsOffset >= (resultsTotal - resultsLimit)" @click="moveForward" ariaLabel="next button" right-icon="chevron-right">Next</c-button>
      </c-flex>
    </div>
  </c-box>
</template>

<script>
import {searchCompany, searchUsers} from "@/services/insight";
import BusinessCard from "@/views/App/Explore/components/search/BusinessCard.vue";
import PeopleCard from "@/views/App/Explore/components/search/PeopleCard.vue";
import {mapGetters} from "vuex";

export default {
  name: "AllSearchResults",
  components: {
    BusinessCard,
    PeopleCard
  },
  data(){
    return {
      resultsLimit: 5,
      resultsOffset: 0,
      loading: false,
      resultsTotal: 0,
      companiesResults: null,
      usersResults: null,
    }
  },
  computed: {
    ...mapGetters({
      resultsType: "explore/getSearchResultsType",
      searchParam: "explore/getSearchParams"
    })
  },
  beforeRouteLeave(){
    this.$emit('hide-sidebar', false);
  },
  mounted() {
    this.$emit('hide-sidebar', true);
    switch (this.resultsType) {
      case 'business':
        this.getCompaniesSearchResultsQuery()
        break;
      case 'user':
        this.getUsersSearchResultsQuery()
        break;
      default:
        console.log('No type here')
    }
  },
  methods: {
    moveForward() {
      if (this.resultsOffset < this.resultsTotal) {
        this.resultsOffset += this.resultsLimit;
        if(this.resultsType === 'business'){
          this.getCompaniesSearchResultsQuery()
        }
        else{
          this.getUsersSearchResultsQuery()
        }
      }
    },
    moveBack() {
      if (this.resultsOffset > 0) {
        this.resultsOffset -= this.resultsLimit;
        if(this.resultsType === 'business'){
          this.getCompaniesSearchResultsQuery()
        }
        else{
          this.getUsersSearchResultsQuery()
        }
      }
    },
    async getCompaniesSearchResultsQuery() {
      this.loading = true;
      const response = await searchCompany({search_param: '%'+this.searchParam + '%', limit: this.resultsLimit, offset: this.resultsOffset})
      this.companiesResults = response.data.company
      this.resultsTotal = response.data.company_aggregate.aggregate.count
      this.loading = false;
    },

    async getUsersSearchResultsQuery() {
      this.loading = true;
      const response = await searchUsers({search_param: '%'+this.searchParam + '%', offset: this.resultsOffset, limit: this.resultsLimit});
      this.usersResults = response.data.user_search
      this.resultsTotal = response.data.user_search_aggregate.aggregate.count
      this.loading = false;
    },
  }
}
</script>

<style scoped>

</style>